<template>
  <div class="relative current-tool" v-show="datalist.length">
    <!-- <ul
      v-if="hasPermission(1092101216)"
      class="absolute ul-dom"
      v-show="showTool"
      @mouseenter="showToolHandle"
      @mouseleave="hideToolHandle"
    >
      <li
        v-show="more"
        v-for="item in datalist"
        :class="`text-center pointer ${
          currentResolution == item.id ? 'current' : ''
        }`"
        :key="item.key"
        @click="selectItem(item)"
      >
        {{ item.text }}
      </li>
      <li
        v-show="!more"
        v-for="(item, key) in datalist2"
        :class="`text-center pointer ${
          currentResolution == item.id ? 'current' : ''
        }`"
        :key="key"
        @click="selectItem(item)"
      >
        {{ item.text }}
      </li>
    </ul> -->
    <!-- {{currentResolution}}
    {{datalist}} -->
    <ul
      v-if="hasPermission(1092101216)"
      class="absolute ul-dom"
      :class="source == 'projection' ? 'ul-down' : 'ul-up'"
      v-show="showTool"
      @mouseenter="showToolHandle"
      @mouseleave="hideToolHandle"
    >
      <li
        v-for="item in definitions"
        :class="
          `text-center pointer ${
            currentResolution == item.templateId ? 'current' : ''
          }`
        "
        :key="item.templateId"
        @click="selectItem(item)"
      >
        {{ item.videoName }}
      </li>
      <!-- <li
        v-show="!more"
        v-for="(item, key) in datalist2"
        :class="`text-center pointer ${
          currentResolution == item.id ? 'current' : ''
        }`"
        :key="key"
        @click="selectItem(item)"
      >
        {{ item.text }}
      </li> -->
    </ul>
    <span
      :class="`text-lightblue pointer resolution-all ${className}`"
      @mouseenter="showToolHandle"
      @mouseleave="hideToolHandle"
      title="切换清晰度"
      >{{ getText }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'ToggleResolution',
  props: {
    resolutionValue: {
      type: [Number, String],
      default: 0
    },
    // 清晰度备选项
    definitions: {
      type: Array,
      default: () => []
    },
    className: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    noToggleResolution: {
      type: Boolean,
      default: false
    },
    more: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      datalist2: [
        { id: 0, text: '标清', key: '01' },
        { id: 1, text: '高清', key: '11' },
        { id: 2, text: '超清', key: '21' },
        { id: 3, text: '原画', key: '31' }
      ],
      // datalist: [
      //   { id: 0, text: "标清", key: "011" },
      //   { id: 1, text: "高清", key: "111" },
      //   { id: 2, text: "超清", key: "211" },
      //   { id: 3, text: "原画", key: "311" },
      // ],
      currentResolution: this.resolutionValue,
      showTool: false
    };
  },
  computed: {
    getText() {
      return (
        this.datalist.find(e => e.id == this.currentResolution)?.text || ''
      );
    },
    datalist() {
      return this.definitions.map(e => ({
        id: e.templateId,
        text: e.videoName
      }));
    }
  },
  watch: {
    resolutionValue(nv) {
      this.currentResolution = nv;
    }
  },

  methods: {
    selectItem(item) {
      this.currentResolution = item.templateId;
      this.showTool = false;
      this.$emit('toggleResolutionHandle', item.templateId);
    },
    showToolHandle() {
      clearTimeout(this.timer);
      if (this.noToggleResolution) {
        return;
      }
      this.showTool = true;
    },
    hideToolHandle() {
      this.timer = setTimeout(() => {
        this.showTool = false;
      }, 200);
    }
  }
};
</script>

<style scoped>
.ul-dom {
  background: rgba(15, 24, 45, 0.8);
  color: rgba(134, 179, 233, 1);
  left: 50%;
  width: 38px;
  z-index: 1000;
  padding: 1px;
}
.ul-up {
  bottom: 100%;
  transform: translate(-50%, -4px);
}
.ul-down {
  top: 100%;
  transform: translate(-50%, 4px);
}
.ul-dom::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: rgba(15, 24, 45, 0.8);
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  z-index: -1;
}
.ul-dom li {
  line-height: 32px;
}
.ul-dom li.hover,
.ul-dom li:hover,
.ul-dom li.current {
  background: rgba(134, 179, 233, 0.3);
}
.resolution-all {
  width: 40px;
  height: 24px;
  border: 1px solid #86b3e9;
  border-radius: 2px;
  display: block;
  text-align: center;
}
.bg-mask {
  background: rgba(0, 0, 0, 0.7);
  border-color: #999;
}
</style>
